"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_class_component_1 = require("vue-class-component");
var Vue = require("vue");
var $ = require("jquery");
var _ = require("lodash");
/**
 * Verticale tabbladen. Staan in de specificatie als een 'tijdlijn'.
 *
 * NOTE: Om het berekenen van de tabhoogte bij reflows te versimpelen staat de inhoud van de tabbladen nog in een extra
 *       container.
 */
var VerticalTabs = /** @class */ (function (_super) {
    __extends(VerticalTabs, _super);
    function VerticalTabs() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VerticalTabs.prototype.data = function () {
        var tabs = JSON.parse(this.content);
        return {
            activeTab: tabs[0] || null,
            tabHeight: null,
            tabs: tabs
        };
    };
    VerticalTabs.prototype.ready = function () {
        this.updateTabHeight();
        // Als het venster van grootte veranderd, of als de gebruiker inzoomt, dan moet de hoogte van de pagina's geüpdatet
        // worden
        $(window).on("resize", this.updateTabHeight);
    };
    Object.defineProperty(VerticalTabs.prototype, "activeTabId", {
        get: function () {
            return this.tabs.indexOf(this.activeTab);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VerticalTabs.prototype, "tabElements", {
        get: function () {
            return this.$els.tabSlider.children;
        },
        enumerable: true,
        configurable: true
    });
    // tslint:disable:next-line no-unused-variable
    VerticalTabs.prototype.setActiveTab = function (tab) {
        this.activeTab = tab;
    };
    /**
     * Werk de tab height bij. Moet worden aangeroepen als het element klaar is met laden, en bij een pagina reflow.
     */
    VerticalTabs.prototype.updateTabHeight = function () {
        this.tabHeight = this.calculateTabHeight();
    };
    /**
     * Geeft de hoogte van het hoogste tabblad, oftewel hoe hoog elk tabblad uiteindelijk moet worden.
     */
    VerticalTabs.prototype.calculateTabHeight = function () {
        return _(this.tabElements)
            .map(function (element) {
            return $(element)
                .children()
                .outerHeight(false);
        })
            .max();
    };
    VerticalTabs = __decorate([
        vue_class_component_1.default({
            props: {
                content: {
                    type: String,
                    required: true
                }
            },
            template: "\n    <div class=\"tabs\">\n      <ul class=\"tabs__list\" role=\"tablist\">\n        <li\n          v-for=\"tab in tabs\"\n          :aria-controls=\"'tab' + $index\"\n          :aria-selected=\"activeTab === tab\"\n          @click.prevent=\"setActiveTab(tab)\"\n          @keydown=\"setActiveTab(tab)\"\n          class=\"tabs__list__item\"\n          role=\"tab\"\n          tabindex=\"0\"\n        >\n          {{ tab.title }}\n        </li>\n      </ul>\n      <div class=\"tabs__container\">\n        <div\n          v-el:tab-slider\n          class=\"tabs__slider\"\n          :style=\"{maxHeight: tabHeight + 'px', transform: 'translateY(' + activeTabId * -tabHeight + 'px)'}\"\n        >\n          <div\n            v-for=\"tab in tabs\"\n            :id=\"'tab' + $index\"\n            :aria-labelledby=\"'tab' + $index\"\n            :aria-hidden=\"activeTab !== tab\"\n            :style=\"{height: tabHeight + 'px'}\"\n            class=\"tabs__tab\"\n            role=\"tabpanel\"\n          >\n            <section class=\"tabs__content\">{{{ tab.body }}}</section>\n          </div>\n        </div>\n      </div>\n    </div>\n  "
        })
    ], VerticalTabs);
    return VerticalTabs;
}(Vue));
exports.VerticalTabs = VerticalTabs;
