"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var Vue = require("vue");
var HeaderSlider_1 = require("./components/vue/HeaderSlider");
var SearchBar_1 = require("./components/vue/SearchBar");
var VerticalTabs_1 = require("./components/vue/VerticalTabs");
// Maak jQuery beschikbaar voor jQuery plugins die afhankelijk zijn van window.jQuery
window.$ = window.jQuery = $;
// Laad selectief Foundation plugins
// tslint:disable no-var-requires
require("foundation/foundation.core");
require("foundation/foundation.offcanvas");
require("foundation/foundation.util.mediaQuery");
require("foundation/foundation.util.motion");
require("foundation/foundation.util.triggers");
require("slick-carousel");
// tslint:enable no-var-requires
$(document).foundation();
// Vue-componenten
Vue.component("header-slider", HeaderSlider_1.HeaderSlider);
Vue.component("search-bar", SearchBar_1.SearchBar);
Vue.component("vertical-tabs", VerticalTabs_1.VerticalTabs);
// Hoofdelement, zodat we componenten kunnen gebruiken
new Vue({ el: "body" });
$(function () {
    // Elementen met data-search-bar moeten bij een klik veranderen naar een zoekbalk. Deze worden in de menu's gebruikt.
    $("[data-search-bar]").one("click", function (event) {
        var searchBar = new SearchBar_1.SearchBar({ el: event.target });
        searchBar.$els.field.focus();
        event.preventDefault();
        event.stopPropagation();
    });
});
